import { useState, useEffect, createContext, useContext } from 'react';
import { Socket } from 'socket.io-client';
import io from 'socket.io-client';

interface SocketContextValue {
  socket: Socket | null;
  connected?: string;
  error: Error | null;
  emit: (event: string, payload: any) => void;
}

const SocketContext = createContext<SocketContextValue>({
  connected: '',
  socket: null,
  error: null,
  emit: (event: string, payload: any) => { },
});

type SocketOptions = {
  reconnect?: boolean;
  transports?: string[];
  upgrade?: boolean;
};

interface SocketProviderProps {
  children: React.ReactNode;
  url: string;
  options?: SocketOptions;
}

const SocketProvider: React.FC<SocketProviderProps> = ({ children, url, options }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [connected, setConnected] = useState<string>();
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const initSocket = async () => {
      try {
        const newSocket = io(url, options);

        newSocket.on('connect', () => {
          setConnected(newSocket.id);
          console.log('Connected!', newSocket.id);
        });

        newSocket.on('disconnect', () => {
          setConnected(undefined);
          console.log('Disconnected from server.');
        });

        newSocket.on('error', (err) => {
          setError(err);
          console.error('Socket error:', err);
        });

        setSocket(newSocket);
      } catch (error) {
        setError(error as Error);
        console.error('Socket connection error:', error);
      }
    };

    initSocket();

    return () => {
      socket?.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, options]);

  // Optional function to emit events
  const emit = (event: string, payload: any) => {
    if (socket) {
      socket.emit(event, payload);
    } else {
      console.warn('Socket not connected, cannot emit event:', event);
    }
  };

  const value = { socket, connected, error, emit };

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};


function useSocket() {
  return useContext(SocketContext);
}

export { SocketProvider, useSocket };