import HomePage from "./pages/HomePage";
import GamesPage from "./pages/GamesPage";
import { createBrowserRouter } from "react-router-dom";

const router = createBrowserRouter([
  { path: "/", element: <HomePage /> },
  { path: "/games", element: <GamesPage /> },
]);

export default router;
